<template>
  <div class="more-filters-button">
    <b-dropdown 
      block 
      no-caret 
      class="dropdown-filters-category" 
      variant="outline-gray-light" 
      menu-class="left-init-side" 
      ref="dropdown_filters"
      :disabled="disabled"
    >
      <template #button-content>
        <div class="d-flex flex-column justify-content-between h-100">
          <span class="text-left align-middle color-gray-light w-100">
            <feather-icon :icon="icon"/>
              {{getDefaultText()}}
            <feather-icon icon="ChevronDownIcon" class="float-right"></feather-icon>
          </span>
        </div>
      </template>
      <b-dropdown-header>
        <div class="category-filters-searcher d-flex flex-column flex-wrap" ref="category_filters_searcher">
          <div 
            v-for="(option, index) in options" 
            :key="index" 
            class="category-item-dropdown position-relative" 
            @click="actionSelected(option, index)"
          >
            <feather-icon icon="CheckIcon" :ref="`check_item_category_${index}`" class="d-none position-absolute check-drop-filter-icon"/>
            <span :ref="`word_item_category_${index}`">
              {{option.text}}
            </span>
          </div>
        </div>
      </b-dropdown-header>
    </b-dropdown>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownHeader,
} from 'bootstrap-vue';
export default {
  name: 'dropdownFilters',
  components: {
    BDropdown,
    BDropdownHeader,
  }, 
  props: {
    options: {
      type: Array,
      required: true
    },
    text: {
      type: String,
    },
    name_prop: {
      type: String, 
      required: true,
    },
    icon: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    one_word: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: {value: null, text: this.$t('search.realty.any')}, 
    }
  },
  methods: {
    actionSelected(option, index) {
      this.selected = option;
      this.$emit('option_selected', option, this.name_prop);
      this.cleanClasses();
      this.$refs.dropdown_filters.hide(true);
      this.$refs[`check_item_category_${index}`][0].classList.remove('d-none');
      this.$refs[`word_item_category_${index}`][0].classList.add('word-break');
    },
    cleanClasses() {
      for (let index = 0; index < this.options.length; index++) {
        this.$refs[`check_item_category_${index}`][0].classList.add('d-none');
        this.$refs[`word_item_category_${index}`][0].classList.remove('word-break');
      }
    },
    getDefaultText() {
      return `${!this.one_word ? `${this.text}: ` : (this.selected.value === null) ? `${this.text}: ` : ''}  ${this.selected.text}`;
    }
  }
}
</script>

<style>
.check-drop-filter-icon {
  position: absolute;
  z-index: 99900000;
}
.dropdown-filters-category > ul {
  width: 100% !important;
  z-index: 999;
}.dropdown-filters-category .dropdown-header {
  padding: 0.5em !important;
}
.category-filters-searcher {
  font-size: 13px;
  max-height: 500px;
  overflow: hidden;
}
.category-item-dropdown {
  padding: 0.7em 0.5em;
  margin: 0 1.3em;
}
.category-item-dropdown > svg {
  left: -1.3em;
  width: 1.2em;
  height: 1.2em;
}
.category-item-dropdown:hover {
  background-color: #EFF3F6;
  cursor: pointer;
}
.word-break {
  word-break: break-all !important;
  font-weight: bolder;
}
</style>