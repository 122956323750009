<template>
  <div class="drop-filters-map d-flex align-items-center">
    <dropdown-filters 
      :icon="`CheckSquareIcon`"
      :options="$t('search.realty.availability_options')"
      :text="$t('search.realty.availability')"
      :one_word="true"
      @option_selected="optionSelected"
      :name_prop="`availability`"
      class="filter-map"
    />
    <dropdown-filters 
      :icon="`TagIcon`"
      :options="category_options"
      :text="$t('search.realty.category')"
      :one_word="true"
      @option_selected="optionSelected"
      :name_prop="`category`"
      class="filter-map"
    />
    <dropdown-filters 
      :icon="`ImageIcon`"
      :options="$t('search.realty.image_options')"
      :text="$t('search.realty.containImage')"
      :one_word="true"
      @option_selected="optionSelected"
      :name_prop="`image`"
      class="filter-map"
    />
    <b-dropdown 
      block 
      no-caret
      class="filter-map w-dropstates" 
      variant="outline-gray-light" 
      menu-class="left-init-side" 
      ref="dropdown_filters"
      :key="update_state"

    >
      <template #button-content>
        <div class="d-flex flex-column justify-content-between h-100">
          <span class="text-left align-middle color-gray-light w-100">
            <span class="search-Map-Pin4"></span>
              {{$t("search.realty.state")}}: {{state.text !== undefined ? state.text :$t('search.realty.any')}}
            <feather-icon icon="ChevronDownIcon" class="float-right" />
          </span>
        </div>
      </template>
      <b-dropdown-header>
        <div ref="category_filters_searcher">
          <locations-state-suggest 
            :states="state_options.filter(i => i.value !== null)"
            @location_selected="locationSelected"
            :state="state"
          />
        </div>
      </b-dropdown-header>
    </b-dropdown>
  </div>
</template>

<script>
import outofhome_service from '@/services/outofhome';
import dropdownFilters from '@/views/pages/outofhome/graphs/dropdownFilters.vue';

import {
  BDropdown,
  BDropdownHeader,
} from 'bootstrap-vue';

export default {
  name: 'filtersMap',
  components: {
    BDropdown,
    BDropdownHeader,
    dropdownFilters,
    locationsStateSuggest: () => import('@/views/pages/outofhome/graphs/locationsStateSuggest.vue'),
  },
  data() {
    return {
      availability: {},
      image: {},
      category: {},
      state: {},
      category_options: [],
      state_options: [],
      update_state: false,
    }
  },
  created() {
    this.getCategories();
    this.getStates();
  },
  methods: {
    locationSelected(location) {
      this.state = location;
      this.$emit('filter_map', location, 'state');
      this.update_state = !this.update_state;
    },
    optionSelected(options, name_prop) {
      this.$emit('filter_map', options, name_prop);
    },
    getCategories() {
      outofhome_service.getCategories().then(response => {
        this.category_options.push({"value": null, "text": this.$t('search.realty.any')});
        response.results.forEach(category => {
          this.category_options.push(category);
        });
      });
    },
    getStates() {
      outofhome_service.getStates().then(response => {
        this.state_options.push({"value": null, "text": this.$t('search.realty.any')})
        response.results.forEach(state => {
          this.state_options.push(state)
        })
      })
    },
  }
}
</script>

<style>
.drop-filters-map {
  /* background-color: red; */
  width: 65%;
  position: absolute;
  height: 44px;
  z-index: 10;
  top: 6em;
  left: 33em;
}
.filter-map {
  margin-right: 0.5em;
  /* background-color: white; */
}
.filter-map .btn {
  padding: 0.5em !important;  
  background-color: white !important;
  border-radius: 10em !important;
}
.w-dropstates {
  width: 200px !important;
}
.w-dropstates .dropdown-header, .w-dropstates .dropdown-menu {
  padding: 0px !important;
}
</style>